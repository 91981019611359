<!------------------------------------------- template ------------------------------------------->
<template>
  <div class="view-root">
    <div class="page-header">
      <p class="common-title">会议文件管理</p>
      <!-- <el-button type="primary" @click="actionCreate(null)" size="medium">上传文件</el-button> -->
      <el-upload
        accept=".pdf"
        action="/"
        :show-file-list="false"
        :before-upload="beforeUpload"
        multiple
      >
        <el-popover placement="top-end" trigger="hover" content="文件仅支持 PDF 格式">
          <el-button slot="reference" size="medium" type="primary">上传文件</el-button>
        </el-popover>
      </el-upload>
    </div>

    <div class="table-margin-top flex-1-overflow-y">
      <el-table :data="dataList" style="width: 100%">
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column prop="file_name" label="文件名称" align="center">
          <template slot-scope="scope">
            <el-input
              v-model.trim="scope.row.cache_file_name"
              @blur="actionUpdateFileName(scope.row)"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="上传时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.create_time | formatDate }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="170" align="center">
          <template slot-scope="scope">
            <el-button size="medium" type="primary" @click="actionPreview(scope.row)" style="margin-right: 10px;">预览</el-button>
            <el-popconfirm placement="top-end" title="确认删除？" @confirm="actionRemove(scope.row)">
              <el-button size="medium" type="primary" slot="reference">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 编辑弹窗 -->
    <el-dialog :visible.sync="isShowDialog" title="上传会议文件">
      <div>
        <el-upload class="upload-demo" drag action="https://jsonplaceholder.typicode.com/posts/" multiple>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
        </el-upload>
        <div style="text-align: right;">
          <span slot="footer" class="dialog-footer">
            <el-button @click="isShowDialog = false">取 消</el-button>
            <el-button type="primary" @click="actionSave()">确 定</el-button>
          </span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<!-------------------------------------------- script -------------------------------------------->
<script>
import '@/assets/common/common.css'
import dayjs from 'dayjs'
import {
  uploadMeetingFlie,
  getMeetingFlies,
  removeMeetingFlie,
  editMeetingFlie,
} from '../../../api/api.js'


export default {
  name: '',
  components: {
  },

  data() {
    return {
      isShowDialog: false,
      meeting_id: this.$route.query.meeting_id,
      dataList: [],
      roomInfo: {
        id: '',
        meeting_id: this.meeting_id,
        name: '',
        location: '',
        room_size: '',
        arrangement: ''
      },
      custom_arrangement: '',
      rules: {
        name: [
          { required: true, message: 'Please input', trigger: 'blur' }
        ],
        location: [
          { required: true, message: 'Please input', trigger: 'blur' }
        ],
        room_size: [
          { required: true, message: 'Please input', trigger: 'blur' }
        ]
      },
      showRoomDialog: false
    }
  },

  /* 一.生命周期函数 */
  created() {
    this.initPage()
  },

  /* 二.监控函数 */
  watch: {
  },

  computed: {
  },

  filters: {
    formatDate (val) {
      return dayjs(val * 1000).format('YYYY-MM-DD HH:mm:ss')

    }
  },

  /* 三.内部功能函数 */
  methods: {
    /* ----------------------事件调用函数------------------------ */
    // 预览文件
    actionPreview (row) {
      window.open(row.file_address_url)
    },
    // 新增
    actionCreate() {
      this.isShowDialog = true
      this.$message('actionCreate');
    },
    // 更新文件名称
    actionUpdateFileName (row) {
      if (row.cache_file_name === '') {
        this.$message.error('文件名称不允许为空');
        row.cache_file_name = row.file_name
      } else {
        editMeetingFlie({
          id: row._id.$id,
          file_name: row.cache_file_name,
        }).then(res => {
          if (res.data.code === 0) {
            row.file_name = row.cache_file_name
            this.$message.success('更新成功');
          } else {
            row.cache_file_name = row.file_name
            this.$message.error(res.data.msg);
          }
        })
      }
    },
    // 更新
    actionUpdate() {
      this.isShowDialog = true
      this.$message('actionUpdate');
    },
    // 保存
    actionSave() {
      this.isShowDialog = false
      this.$message('actionSave');
    },
    // 删除
    actionRemove(row) {
      console.log(row)
      // removeMeetingFlie({
      //   id: 
      // })
      removeMeetingFlie({
        id: row._id.$id,
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('删除成功');
          this.initPage()
        } else {
          this.$message.error(res.data.msg);
        }
      })
    },

    /* ----------------------内部功能函数------------------------ */
    // 内部功能函数注释
    initPage() {
      getMeetingFlies({
        meeting_id: this.meeting_id,
      }).then(res => {
        if (res.data.code === 0) {
          this.dataList = res.data.data.map(item => {
            return {
              ...item,
              cache_file_name: item.file_name,
            }
          })
        } else {
          this.$message.error(res.data.msg);
        }
      })
    },
    async beforeUpload(file) {
      if (file.type !== 'application/pdf') {
        this.$message.error('请上传 PDF 格式文件');

        return false
      }
      console.log(file)
      uploadMeetingFlie({
        file,
        meeting_id: this.meeting_id,
        fileName: file.name.split(".")[0],
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('上传成功');
          this.initPage()
        }
      })
    },

    /* ----------------------服务请求函数------------------------ */
    // 服务请求函数注释
    ajaxXxx() {

    }
  }
}
</script>

<!-------------------------------------------- style -------------------------------------------->
<style scoped lang="scss">
.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center
}
</style>
